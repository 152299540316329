// Core

@import "black-dashboard-react/custom/functions";
@import "~bootstrap/scss/functions";

@import "black-dashboard-react/custom/variables";
@import "~bootstrap/scss/variables";

@import "black-dashboard-react/custom/mixins";
@import "~bootstrap/scss/mixins";

 // Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/print";

 // Custom components

 @import "black-dashboard-react/custom/alerts.scss";
 @import "black-dashboard-react/custom/badges.scss";
 @import "black-dashboard-react/custom/buttons.scss";
 @import "black-dashboard-react/custom/dropdown.scss";
 @import "black-dashboard-react/custom/footer.scss";
 @import "black-dashboard-react/custom/forms.scss";
 @import "black-dashboard-react/custom/images.scss";
 @import "black-dashboard-react/custom/modal.scss";
 @import "black-dashboard-react/custom/navbar.scss";
 @import "black-dashboard-react/custom/type.scss";
 @import "black-dashboard-react/custom/tables";
 @import "black-dashboard-react/custom/checkboxes-radio";
 @import "black-dashboard-react/custom/fixed-plugin";
 @import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
 @import "black-dashboard-react/custom/misc.scss";
 @import "black-dashboard-react/custom/rtl.scss";
 @import "black-dashboard-react/custom/input-group.scss";


 // Vendor / Plugins

@import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards

@import 'black-dashboard-react/custom/card';
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-plain";

// React differences
@import "black-dashboard-react/react-differences/react-differences";


::-webkit-scrollbar {
    width: 8px;
}
    
    /* Track */
::-webkit-scrollbar-track {
    background: #1E1E2E;
    width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #305FF4;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: violet; 
}


/* Scroll to top Button */
.back-to-top {
    position: fixed;
    visibility: visible;
    opacity: 1;
    right: 18px;
    bottom: 60px;
    z-index: 99999;
    background: #8eabcf;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #6776f4;
    color: #fff;
    cursor: pointer;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }



  // Custom styles
  // .custom-button {
  //   @include button;
  // }

  // .custom-forms{
  //   @include forms;
  // }

  .jumbotron {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }

  @media print {
    .no-print {
      display: none;
    }
  
    .print-only {
      display: block;
    }
  }